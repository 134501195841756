@charset "utf-8";

@import "_mixins";

@include font-face(JetBrainsMono, fonts/JetBrainsMono-Regular);
@include font-face(JetBrainsMonoItalic, fonts/JetBrainsMono-Regular);

// Define defaults for each variable.
// $base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-family: JetBrainsMono !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$color-base-base: #fff !default;
$color-base-essential: #000 !default;
$color-base-project: #0056b3 !default;
$color-base-phantom: #ddd !default;
$color-base-path: #ddd !default;

// Статусные цвета
// 5 цветов для статусов. Они не наследуются от основной палитры, но влияют на цвета статусные текста и фонов блоков. С префиксом color-base-
$color-base-success: #00985f !default;
$color-base-warning: #f90 !default;
$color-base-alert: #f33 !default;
$color-base-system: #778a9b !default;
$color-base-normal: #dde3e5 !default;

// Цвета фонов
// С префиксом --color-bg-
$color-bg-brand: $color-base-project;
$color-bg-border: transparentize($color-base-phantom, .95);
$color-bg-stripe: transparentize($color-base-phantom, .96);
$color-bg-ghost: transparentize($color-base-phantom, .9);
$color-bg-default: $color-base-essential;
$color-bg-tone: transparentize($color-base-phantom, .9);
$color-bg-soft: transparentize($color-base-essential, .97);
$color-bg-success: $color-base-success;
$color-bg-alert: $color-base-alert;
$color-bg-warning: $color-base-warning;
$color-bg-normal: $color-base-normal;
$color-bg-system: $color-base-system;
$color-bg-link: color($color-base-path h(-7%) s(-13) l(+14) a(40%));

// Цвета текста
// С префиксом --color-typo-
$color-typo-brand: $color-base-project;
$color-typo-primary: $color-base-base;
$color-typo-secondary: transparentize($color-base-base, .6);
$color-typo-ghost: transparentize($color-base-base, .3);
$color-typo-disable: transparentize($color-base-base, .3);
$color-typo-success: $color-base-success;
$color-typo-warning: $color-base-warning;
$color-typo-alert: $color-base-alert;
// Цвета ссылок
// С префиксом --color-link
$color-link: $color-base-path;
$color-link-external: $color-base-path;
$color-link-minor: color($color-base-path h(+22) s(-80%) l(+13%));
$color-link-hover: color($color-base-path l(-7%));

//$text-color:       $color-typo-primary;
//$background-color: $color-bg-default;
//$brand-color:      $color-typo-brand;

$grey-color:      $color-bg-ghost;
$grey-color-light: lighten($color-bg-ghost, 10%);
$grey-color-dark:  darken($color-bg-ghost, 25%);

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "whitepaper/base",
  "whitepaper/layout",
  "whitepaper/syntax-highlighting"
;
